@import "./variables.scss";
@import "bootstrap/scss/bootstrap.scss";

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  col,
  td,
  th {
    @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
      @for $i from 1 through $grid-columns {
        &.col#{$infix}-#{$i} {
          width: 100% / $grid-columns * $i;
        }
      }
    }
  }
}

::selection {
  background: $gray-900;
  color: $gray-100;
}

textarea {
  resize: vertical;
  min-height: 120px;
}

.typeahead-container {
  width: 100% !important;
}

.bulk_actions_button_journey_map button {
  color: white !important;
  width: auto !important;
  // font-size: auto !important;
  font-weight: bold !important;
  background-color: #7f8ea1 !important;
  height: 34.6px !important;
}

.folder_name_clickable:hover {
  text-decoration: underline;
}
.comments_modal_class {
  margin: 0rem !important;
  //margin-top: 1.5em !important;
  //height: 95% !important;
  overflow-y: hidden !important;
}

.comments_modal {
  height: 400px !important;
  overflow-y: scroll !important;
}
.delete_comment_warning .modal-content {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.input_root_comment_box {
  padding: 2px !important;
  //height: 105px !important;
  border-radius: 6px !important;
  flex: 1 1 0% !important;
  width: 90% !important;
  margin-top: 0.3em;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.input_inner_comment_box {
  padding: 2px !important;
  //border: 1px solid black !important;
  border-radius: 6px !important;
  flex: 1 1 0% !important;
  width: 99% !important;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.root_comment_style textarea {
  min-height: 70px !important;
  width: 100% !important;
  // border: 1px solid black !important;
  // border-top-left-radius: 3px !important;
  // border-top-right-radius: 3px !important;
  border: none !important;
  outline: none !important;
}

.root_comment_style textarea:focus {
  outline: none !important;
}

//Merging Style

.box-content .initiate_merge_btn {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.box-file-upload .initiate_merge_btn {
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-bottom: 2px;
}
.box-content:hover .initiate_merge_btn {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.dropdown-item:hover {
  background-color: greenyellow !important;
}
.box-content-diagram .initiate_merge_btn {
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-top: -2px;
}

.box-content-diagram:hover .initiate_merge_btn {
  opacity: 1;
  transition: opacity 0.3s ease;
}

//for process flows separate .metric-charts-boxes

.box-content-processFlows .initiate_merge_btn {
  opacity: 0;
  transition: opacity 0.3s ease;
  margin-top: -2px;
}

.box-content-processFlows:hover .initiate_merge_btn {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.initiate_merge_btn_empty_box {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  padding-bottom: 4px;
  padding-left: 4px;
  left: 0;
  opacity: 0;
}

.empty-box:hover {
  .initiate_merge_btn_empty_box {
    opacity: 1;
    transition: opacity 0.3s ease;
  }
}
thead > tr > th {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.8rem;
}

.thead-light > tr > th {
  border: none !important;
}

.dropdown-menu-right {
  transform: translate3d(-1px, 31px, 0px) !important;
}

.organization_header_image {
  width: auto;
  height: 30px;
}

.clear_btn_img_cmnt {
  opacity: 0; /* Start with the element hidden */
  transition: opacity 0.3s; /* Add a smooth transition effect for hover */
}

.file_icon_cmnt_wrapper:hover {
  .clear_btn_img_cmnt {
    opacity: 1;
    z-index: 9999;
  }
}
#splash-screen {
  #root {
    padding: 0;
  }

  .splash-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .splash-frame {
    width: 530px;
    padding: 2rem;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
  }

  .logo-block {
    text-align: center;
    margin-bottom: 2rem;
  }
}

#root {
  padding-top: $main-menu-height;
  padding-bottom: $footer-height;
}

#main-content {
  padding: $main-content-frame-padding;
  padding-bottom: $footer-height + $main-content-frame-padding;
}

.pagination-block {
  margin-top: 1rem;

  .pagination-meta-info {
    align-self: flex-end;
  }

  .pagination {
    justify-content: flex-end;
    margin: 0;
    font-weight: 500;
  }
}

.footer {
  display: flex;
  align-items: center;
  height: $footer-height;
  width: 100%;
  bottom: 0;
  position: fixed;
  background-color: #fff;
  border-top: 1px solid $gray-300;
  padding: 0 $main-content-frame-padding;
  font-size: 0.85rem;
  font-weight: 500;
  color: $gray-600;

  div {
    flex-grow: 1;
  }

  .footer-links {
    text-align: right;
  }

  ul {
    margin: 0;
  }
}

.modal-header {
  padding: 1rem 1rem 0.5rem 1rem;
}

.modal-title {
  text-transform: uppercase;
  font-weight: 400;
}

.modal-body {
  padding: 0.5rem 1rem;
}

.modal-footer {
  padding: 0.5rem 0.75rem 0.75rem 0.75rem;
}

#journey-map-editor-page {
  .footer {
    display: none;
  }
}

.narrow {
  width: 1%;
  white-space: nowrap;
}

.nl2br {
  white-space: pre-line;
}

.parent_item_bulk_typeahead:hover {
  background-color: #3aa7d0 !important;
}
.parent_item_bulk_typeahead {
  padding: 4px !important;
  border-radius: 2px;
}
.empty-data-message {
  padding: 3rem;
  text-align: center;
  color: $gray-600;

  img {
    margin-bottom: 2rem;
  }

  .message {
    color: $gray-500;
    font-size: 1.5rem;
  }
}

.empty-data-message_chart {
  text-align: center;
  color: $gray-600;

  .chart_message {
    margin-top: -1em;
    color: $gray-500;
    font-size: 1.5rem;
  }
}

.dropdown-menu:hover {
  background-color: white !important;
}
.dropdown-item:hover {
  background-color: green !important;
}
.error-boundary-box {
  border: 4px solid #eab7b7;
  border-radius: 5px;
  background-color: #f7dada;
  padding: 1rem;
}

.loading-box {
  text-align: center;
  padding: 5rem;

  .spinner {
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 50px;

    & .path {
      stroke: $primary;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}

.loading-no-padding {
  padding: 0;
}

.btn-loader {
  text-align: center;
  padding: 5rem;

  .spinner {
    animation: rotate 2s linear infinite;
    height: 20px;

    & .path {
      stroke: white !important;
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
}

.btn-loader-padding {
  padding: 0;
}

.page-heading {
  display: flex;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  z-index: 500;

  h1 {
    flex-grow: 1;
    align-self: center;
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
    margin: 0;
  }

  .actions {
    flex-grow: 0;
    align-self: center;
  }
}

.btn-mini-icon {
  padding: 0.15rem 0.2rem;
  border: 0;
  line-height: 1;
  background-color: transparent;
  border-radius: $btn-border-radius-sm;
  color: #000;

  @include hover {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: darken($gray-200, 10%);
  }

  &:focus,
  &.focus {
    outline: 0;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 0.1rem $gray-400;
  }

  .material-icons {
    font-size: 0.9rem;
  }
}

.box-content-white {
  color: #fff;

  .btn-mini-icon {
    color: #fff;
  }
}

.table-journey-maps {
  .persona-box {
    display: flex;
    align-items: center;
    margin-bottom: 2px;

    .avatar {
      margin-right: 0.75rem;
      border-radius: $border-radius-sm;
    }
  }
}

.journey-map-template-selection,
.persona-selection {
  .card {
    min-height: 72px;
  }

  label {
    cursor: pointer;
  }

  label:hover {
    border-color: lighten($primary, 35%);
  }

  .selected {
    border-color: lighten($primary, 20%) !important;
    background-color: lighten($primary, 40%) !important;
  }
}

#editor-container {
  .print-heading {
    display: none;
    text-align: center;
    padding: 1.5rem 0;

    img {
      height: 50px;
    }
  }

  .print-heading-logo {
    display: none;
    text-align: left;
    padding: 1.5rem 0;

    img {
      height: 50px;
    }
  }

  .print-heading-image {
    display: none;
    text-align: right;
    padding: 1.5rem 0;

    img {
      margin: 0px 15px 0px 15px;
      height: 80px;
      width: auto;
    }
  }
}

.jmap-description {
  margin-left: $main-content-frame-padding;
  margin-bottom: 0.5rem;
  color: #696969;
  // font-style: italic;
}

#editor-heading-container {
  min-width: 100vw;
  margin-left: $main-content-frame-padding * -1;
  margin-bottom: 1rem;

  .page-heading-suffix {
    font-size: 0.8rem;
    margin-left: $main-content-frame-padding;
    margin-bottom: 0.6rem;

    a {
      font-weight: 500;
    }
  }

  .page-heading {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0;
  }

  h1 {
    flex-grow: inherit;
    position: sticky;
    left: $main-content-frame-padding;
  }

  .actions {
    z-index: 100;
    position: sticky;
    right: $main-content-frame-padding;
  }

  .btn-dots {
    font-size: 2rem;
    color: $gray-600;

    &:hover {
      color: $gray-900;
    }
  }
}
.comment_input_tools {
  color: #65676b;
}
.comment_input_tools:hover {
  color: black;
}
#editor {
  min-width: 100vw;
  margin-left: $main-content-frame-padding * -1;
  padding-top: 1rem;
  padding-bottom: $main-content-frame-padding;

  .persona-container {
    display: flex;
    padding-left: 5px;
    padding-right: 5px;

    .add-button-grid {
      padding: 0;

      .btn:focus {
        box-shadow: none;
      }

      .mdi {
        font-size: 1.8rem;
      }
    }

    .persona-box:hover {
      border-color: lighten($primary, 30%);
      background-color: lighten($primary, 40%);
    }
  }

  .persona-box {
    display: flex;
    margin-bottom: 1rem;
    margin-right: 0.8rem;
    align-items: center;
    padding: 7px;
    position: sticky;
    border: 1px solid darken($journey-map-persona-background-color, 10%);
    background-color: $journey-map-persona-background-color;
    border-radius: 5px;
    cursor: pointer;

    .persona-image {
      border-radius: $border-radius;
      margin-right: 1rem;
    }

    .persona-name {
      font-size: 1.2rem;
    }
  }

  .comment_box_img {
    display: flex;
    align-items: center;
    padding: 2px;
    border-radius: 4px;
    background-color: white;
    opacity: 0.3;
    margin-left: 0.5em !important;
  }
  .comment_box_img:hover {
    opacity: 1;
  }
  .stages {
    display: flex;
    flex-flow: row nowrap;
    min-width: fit-content;
    align-items: stretch;
    position: sticky;
    top: $main-menu-height;
    z-index: 2;
    margin-left: $editor-space-between-boxes;
    transition: padding 0.5s, margin 0.5s;
  }

  .stages.sticky {
    background-color: #fff;
    padding: $editor-space-between-boxes;
    margin-left: 0;
    box-shadow: 0 0 4px 0px #999;
  }

  // to be removed -- shaheer

  .chill_container {
    display: flex;
    overflow-x: auto;
  }

  .chill_fixed_div {
    flex-shrink: 0; /* Prevents the fixed div from shrinking */
    width: 568px; /* Set the width of the fixed div as needed */
    position: sticky;
    left: 0;
    z-index: 9999;
  }

  .chill_scroll_div {
    flex-shrink: 0; /* Prevents the scroll divs from shrinking */
    width: 100%; /* Set the width of the scroll divs as needed */
  }

  // for cusomer journey

  .cj_freeze_container {
    flex-shrink: 0; /* Prevents the fixed div from shrinking */
    width: 568px; /* Set the width of the fixed div as needed */
    position: sticky;
    left: 0;
    z-index: 9999;
  }

  .cj_scrollable_container {
    flex-shrink: 0; /* Prevents the scroll divs from shrinking */
    width: auto; /* Set the width of the scroll divs as needed */
  }

  //---

  // till here

  //vertical freeze
  .wrapper-container {
    display: flex;
    overflow-x: auto; /* Enable horizontal scrolling */
  }

  .frozen-content {
    position: sticky;
    top: 0;
    background-color: #f9f9f9;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    /* Set the width of the frozen content as needed */
    width: 568px;
  }

  .scrollable-content {
    /* Set the width of the scrollable content as needed */
    width: 100%;
    /* Adjust the padding and other styles as needed */
    padding: 20px;
    background-color: #fff;
  }

  .customer_jounrey_fix_scroll {
    // display: flex;
    // flex-flow: row nowrap;
    // align-items: stretch;
    position: sticky;
    top: $main-menu-height + 53px;
    z-index: 999;
    margin-left: $editor-space-between-boxes;
    transition: padding 0.5s, margin 0.5s;
  }

  .customer_jounrey_fix_scroll.sticky {
    background-color: #fff;
    padding: $editor-space-between-boxes;
    margin-left: 0;
    z-index: 999;
    box-shadow: 0 0 4px 0px #999;
  }

  .custom-modal_process_flow {
    width: 900px !important; /* This makes sure it's responsive */
    height: 900px !important; /* Set your custom height here */
    margin: 0 auto; /* Center the modal horizontally */
  }

  .custom-modal_process_flow .modal-body {
    height: 100px !important;
    width: 100px !important; /* Set your custom max-height here */
    overflow-y: auto; /* Add vertical scrollbar if content exceeds max-height */
  }

  .stage {
    display: flex;
    margin-right: $editor-space-between-boxes;
    background-color: $journey-map-stage-background-color;
    padding: $editor-space-between-boxes;
    border-radius: $border-radius-lg;
  }

  .stage-name {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
  }

  .stage-settings-btn {
    padding: 0;
    margin: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  .lanes.dragging-over {
    background-color: #fef5ee;
  }

  .lane {
    background-color: #fff;
    margin: 0.7rem 0;
  }

  .lane-heading {
    display: flex;
    padding: $editor-space-between-boxes;
    border-bottom: 1px solid $gray-200;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8rem;
  }

  .lane-heading-actions {
    margin-right: 1rem;
    display: flex;
  }

  .lane-heading-name {
    position: sticky;
    left: 1rem;
  }

  .lane-body {
    padding: $editor-space-between-boxes;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    z-index: -9999;
  }

  .lane-settings-btn {
    padding: 0;
    margin: 0;
    line-height: 1;
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  .lane-move-btn {
    padding: 0;
    margin: 0;
    margin-right: 0.5rem;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  //empty boxes for only steps ** style

  .empty-box-for-step:hover {
    .initiate_merge_btn_empty_box {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }

  .empty-box-for-step,
  .empty-box-for-step-read-only {
    width: $editor-box-width;
    margin-right: $editor-space-between-boxes;
    border-radius: $border-radius-lg;
    border: 2px dashed $gray-300;
    display: flex;
    position: relative;
  }

  .empty-box-for-step-read-only {
    border: 2px solid $gray-200;
    min-height: 100px;
  }

  .empty-box-for-step > button {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: $gray-300;
    background: transparent;
    border: 0;
    outline: 0;
    transition: color 0.3s ease;

    &:hover {
      color: $gray-400;
    }
  }

  //****

  .empty-box,
  .empty-box-read-only {
    //width: $editor-box-width;
    margin-right: $editor-space-between-boxes;
    border-radius: $border-radius-lg;
    border: 2px dashed $gray-300;
    display: flex;
    position: relative;
  }

  .empty-box-read-only {
    border: 2px solid $gray-200;
    min-height: 100px;
  }

  .empty-box > button {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: $gray-300;
    background: transparent;
    border: 0;
    outline: 0;
    transition: color 0.3s ease;

    &:hover {
      color: $gray-400;
    }
  }

  .box {
    display: flex;
    //width: $editor-box-width;
    min-height: 100px;
    background-color: $journey-map-box-background-color;
    margin-right: $editor-space-between-boxes;
    border-radius: $border-radius-lg;
  }
  .without_background_color_box {
    display: flex;
    //width: $editor-box-width;
    height: 50px;
    //background-color: $journey-map-box-background-color;
    margin-right: $editor-space-between-boxes;
    border-radius: $border-radius-lg;
  }

  .box-empty {
    background-color: transparent;
    border: none;
  }

  .box-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: $journey-map-box-content-padding;
    position: relative;
  }

  .box-diagram {
    display: flex;
    //width: $editor-box-width;
    min-height: 100px;
    align-items: center;
    justify-content: center;
    background-color: $journey-map-box-background-color;
    margin-right: $editor-space-between-boxes;
    border-radius: $border-radius-lg;
  }
  .box-content-diagram {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    position: relative;
    padding: $journey-map-box-content-padding;
  }

  .box-content-diagram:hover {
    .box-actions {
      opacity: 1;
    }
  }

  .box-content-processFlows {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: space-between;
    justify-content: space-between;
    position: relative;
    border-radius: 2px;
    //padding: $journey-map-box-content-padding;
  }

  .box-content-processFlows:hover {
    .box-actions {
      opacity: 1;
    }
  }
  // .box-content-processFlows:hover {
  //   .center_btn_diagram {
  //     opacity: 1;
  //   }
  // }
  // .center_btn_diagram {
  //   opacity: 0;
  // }

  .box-title {
    font-weight: 600;
    flex-grow: 0;
  }

  .box-body {
    font-size: inherit;
    flex-grow: 1;
    white-space: pre-line;
  }

  .box-actions {
    flex-grow: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .box-content:hover {
    .box-actions {
      opacity: 1;
    }
  }

  .box-badge {
    position: absolute;
    top: -6px;
    right: -6px;
    color: #fff;
    width: 24px;
    height: 24px;
    border-radius: 50rem;
    border: 2px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .add-button {
    display: flex;
    margin: 0;
    margin-left: ($editor-space-between-boxes * -1);
    width: $editor-space-between-boxes;
    z-index: 1;
    opacity: 0;
    background-color: transparent;
    border: none;
    border-radius: $border-radius-lg;
    padding: 0;
    outline: 0;
    transition: opacity 0.3s ease;

    div {
      align-self: center;
      font-size: 22px;
      color: #f89b47;
      transition: color 0.3s ease;
    }

    div:hover {
      color: darken(#f89b47, 10%);
    }

    .mdi {
      margin-left: -7px;
    }

    &:hover {
      opacity: 1;
      border: none;
      background-color: #fde3cc;
    }
  }

  .stage-steps-list {
    padding: $editor-space-between-boxes;
    margin: ($editor-space-between-boxes * -1);
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    transition: background-color 0.2s ease;
  }

  .stage-steps-list.dragging-over {
    border-radius: $border-radius-lg;
    background-color: #fef5ee !important;
  }

  .stage-steps-list.drag-source {
    border-radius: $border-radius-lg;
    background-color: #edf7fa;
  }

  .wysiwyg {
    p,
    ul,
    ol {
      margin: 0;
      padding: 0;
    }

    ul,
    ol {
      padding-left: 1rem;
    }
  }

  .add-new-lane {
    padding-top: $editor-space-between-boxes;

    button {
      position: sticky;
      left: $editor-space-between-boxes;
    }
  }

  .step-rating-svg-line {
    fill: transparent;
    stroke-width: 6px;
    stroke: lighten($primary, 30%);
  }

  .step-rating-circle {
    stroke-width: 3px;
    stroke: #fff;
    fill: $primary;
    cursor: pointer;
    transition: stroke 0.3s ease;
  }

  .step-rating-circle-level1 {
    fill: $step-rating-level1-fill-color;

    &:hover {
      stroke: darken($step-rating-level1-fill-color, 7%);
    }
  }

  .step-rating-circle-level2 {
    fill: $step-rating-level2-fill-color;

    &:hover {
      stroke: darken($step-rating-level2-fill-color, 7%);
    }
  }

  .step-rating-circle-level3 {
    fill: $step-rating-level3-fill-color;

    &:hover {
      stroke: darken($step-rating-level3-fill-color, 7%);
    }
  }

  .step-rating-circle-level4 {
    fill: $step-rating-level4-fill-color;

    &:hover {
      stroke: darken($step-rating-level4-fill-color, 7%);
    }
  }

  .step-rating-circle-level5 {
    fill: $step-rating-level5-fill-color;

    &:hover {
      stroke: darken($step-rating-level5-fill-color, 7%);
    }
  }
  .img_box_empty {
    opacity: 0;
    transition: opacity 0.3s ease;

    .mdi {
      font-size: 0.875rem !important;
    }
  }
  .upload-box {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: $gray-500;
    border-radius: $border-radius-lg;
    border: 2px dashed $gray-300;
    background-color: #fff;
    cursor: default;
    transition: color 0.3s ease;
    height: 100%;

    .mdi {
      font-size: 2.2rem;
    }

    &:hover {
      color: $gray-700;
    }
  }

  .box-file-upload {
    display: flex;
    align-items: stretch;
    min-height: 140px;
  }

  .file-drop-container {
    flex-grow: 1;
    display: flex;
    border-radius: $border-radius-lg;
    transition: background-color 0.3s ease;
    position: relative;
    align-items: center;
    justify-content: center;

    .uploading {
      position: absolute;
      width: $editor-box-width;

      .loading-box {
        padding: 1rem;
      }
    }
  }

  .file-drop-container.dragging {
    background-color: darken($journey-map-box-background-color, 5%);

    .upload-box {
      background-color: darken($journey-map-box-background-color, 5%);
    }
  }

  .step-custom-image {
    max-height: $editor-box-width;
    max-width: $editor-box-width;
    border-radius: $border-radius-lg;
  }

  .step-custom-image-delete-button {
    //border: 1px solid red !important;
    position: absolute;
    // right: 2px;
    top: 4px;
    padding: 2px !important;
    width: 100%;
    color: #fff;
    left: 0;

    button {
      background-color: #fff;
      opacity: 0;
      transition: opacity 0.3s ease;

      &:hover {
        opacity: 1 !important;
      }
    }
  }

  .box-file-upload:hover .step-custom-image-delete-button button {
    opacity: 0.3;
  }

  .box-file-upload:hover .initiate_merge_btn {
    opacity: 1;
    transition: opacity 0.3s ease;
  }
  .upload-box:hover .img_box_empty {
    opacity: 1;
    transition: opacity 0.3s ease;
  }

  .img_box_empty {
    margin-bottom: 0.5em !important;
    margin-left: 0.2em !important;
  }
  .files-list {
    padding-left: 0;
    margin: 0 ($journey-map-box-content-padding * -1);
    list-style: none;
  }

  .file-item {
    display: flex;
    transition: background-color 0.3s ease;
    padding-left: $journey-map-box-content-padding;
    padding-right: $journey-map-box-content-padding;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);

      button {
        opacity: 1;
      }
    }

    button {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  .channel-item {
    display: flex;
    align-items: start;
    margin-bottom: 5px;

    .icon {
      font-size: 1.1rem;
      margin-right: 5px;
      border-radius: 50rem;
      text-align: center;
      min-width: 27px;
      background-color: $primary;
      color: white;
    }

    .details {
      font-weight: inherit;
      flex-grow: 1;
    }

    .title {
      font-weight: 600;
      margin-top: 4px;
      display: flex;

      > div {
        flex-grow: 1;
      }

      .btn {
        opacity: 0;
        transition: opacity 0.3s ease;
      }
    }

    .description {
      font-size: 0.75rem;
    }

    &:hover {
      .title .btn {
        opacity: 1;
      }
    }
  }

  .metric-charts-boxes {
    position: relative;

    .only__icon {
      opacity: 0;
    }

    .metric-actions {
      display: flex;
      justify-content: space-between;
      width: 100% !important;
      position: absolute;

      bottom: 0;
      opacity: 1;
      transition: opacity 0.3s ease;
    }

    .box {
      margin-top: 1rem;
      flex-direction: column;
      background-color: #fff;
      overflow: hidden;
      position: relative;

      &:hover {
        .metric-actions {
          opacity: 1;
        }
        .only__icon {
          opacity: 1;
        }
      }
    }

    .box:first-child {
      margin-top: 0;
    }

    .chart-title {
      font-weight: 500;
      font-size: 0.85rem;
      padding: 0.2rem 0.5rem;
      color: #fff;
      background-color: $gray-500;
    }

    .last-reading {
      font-weight: 600;
      padding: 0.7rem 0.5rem;
      color: #fff;
      background-color: $gray-400;
    }

    .kpi-chart-data-box {
      height: 120px;
      border: 1px solid #e1e1e1;
      border-radius: 0.3rem;
      border-top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      padding-top: 5px;
      background-color: #fff;
    }

    .reading-value {
      font-size: 1.7rem;
      line-height: 1;
    }

    .reading-time {
      font-size: 0.9rem;
    }

    .chart-neutral {
      background-color: #f5f5f5;
    }

    .chart-positive {
      .chart-title {
        background-color: #92b85c;
      }

      .last-reading {
        background-color: #a5c478;
      }
    }

    .chart-negative {
      .chart-title {
        background-color: #e84431;
      }

      .last-reading {
        background-color: #ec6556;
      }
    }
  }
}

#menu-popover,
#edit-popover,
#delete-popover {
  font-size: $font-size-base;
}

#menu-popover {
  .list-group-item {
    padding: 0.3rem 0.8rem;
  }
}

#delete-popover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  .delete-dialog {
    padding: 0.7rem;
  }
}

#edit-popover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  max-width: 400px;
  min-width: 400px;
}

.add-lane-modal-body {
  .btn {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.ql-editor {
  min-height: 100px;
  max-height: 250px;
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;
}

.icon-input-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  input {
    display: none;
  }

  label {
    margin: 0;
    color: #fff;
    background-color: lighten($secondary, 25%);
    font-size: 1rem;
    margin: 5px;
    border-radius: 50rem;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  label.selected {
    background-color: darken($secondary, 10%);
  }
}

.icon-input-list-colors {
  label {
    font-size: 1rem;
    width: 20px;
    height: 20px;
  }
}

.icon-input-list-channels {
  justify-content: space-between;

  label {
    background-color: lighten($primary, 25%);
    font-size: 1.5rem;
    width: 45px;
    height: 45px;
  }

  label.selected {
    background-color: darken($primary, 10%);
  }
}

.step-badge-container {
  background-color: $body-bg;
  padding: 1rem;
  border-radius: $border-radius;
}

.editable-field-container {
  display: flex;

  .col-form-label {
    flex-grow: 1;
    align-self: center;
  }

  button {
    align-self: center;
    color: $gray-500;
  }

  .editable-label {
    border: 0;
    background-color: transparent;
    margin: 0;
    padding: 0;
    width: 100%;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $gray-100;
    }
  }
}

.lane-type-help-container {
  background-color: #ebf8ff;
  padding: 1rem;
  border-radius: $border-radius;
}

.sub-navigation {
  margin-top: $main-content-frame-padding * -1;
  margin-left: $main-content-frame-padding * -1;
  margin-right: $main-content-frame-padding * -1;
  background-color: $sub-navigation-bg;
  border-bottom: 1px solid $gray-300;

  .nav-link {
    border-radius: 0;
    color: $sub-navigation-color;
    background-color: $sub-navigation-bg;
    font-weight: 500;
  }

  .nav-link.active {
    color: $sub-navigation-active-color;
    background-color: $sub-navigation-bg;
    border-bottom: 2px solid $sub-navigation-active-border-color;
  }
}

.color-picker {
  background-color: #fff;
  border-radius: $input-border-radius;
  border: $input-border-width solid $input-border-color;
  cursor: pointer;
  display: inline-flex;
  align-items: stretch;
  overflow: hidden;

  .swatch {
    width: 30px;
    margin-right: 0.75rem;
    border-right: $input-border-width solid $input-border-color;
  }

  .text {
    margin-right: 0.75rem;
    text-transform: uppercase;
  }
}

#journey-map-tree-view {
  cursor: grab;
  position: absolute;
  left: 0;
  right: 0;
  top: $main-menu-height + 70px /* to account for the title */;
  bottom: $footer-height;

  .tree-node {
    min-width: 100px;
    max-width: 200px;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: grab;
  }

  .tree-node-journey-map {
    background-color: #fff;
  }

  .tree-node-step {
    background-color: $journey-map-stage-background-color;
  }

  .node-name {
    color: $gray-700;

    line-height: 1.5;
    max-height: 2.7;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .node-actions {
    text-align: right;
  }
}

.integration-service {
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fff;
  align-items: center;
  border-radius: $border-radius;

  .logo {
    flex-grow: 1;
  }

  .actions .btn {
    width: 200px;
  }
}

.create-kt-account-container {
  margin-top: 1.2rem;
  font-size: 0.8rem;
  text-align: center;

  p {
    display: block;
    padding: 0;
    margin: 0;
  }
}

.create-metric-form {
  background-color: $body-bg;
  border-radius: $border-radius;
  padding: 1rem;
  margin-bottom: 1rem;
}

.metric-items {
  margin-bottom: 1rem;

  .metric-item-container {
    background-color: $body-bg;
    border-radius: $border-radius;
    padding: 0.5rem;
    margin-bottom: 1rem;
    overflow: hidden;
  }

  .highlight-box {
    border: 1px dotted $primary;
    animation: highlight 1s;
  }

  @keyframes highlight {
    from {
      background-color: lighten($primary, 10);
    }
    to {
      background-color: $body-bg;
    }
  }

  .metric-item {
    display: flex;
  }

  .metric-details {
    flex-grow: 1;
  }

  .meta-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 0.8rem;
    color: $gray-600;

    li {
      display: inline-block;
      margin-right: 2rem;
    }
  }
}

.metric-data-upload-form {
  margin: -0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: $body-bg-light;

  .file-upload-box {
    margin-top: 1rem;
    border: 2px dashed $primary;
    border-radius: $border-radius-lg;

    .upload-box {
      padding: 2rem;
      text-align: center;

      .mdi {
        font-size: 2.5rem;
        color: $gray-500;
      }
    }

    .uploading {
      .loading-box {
        padding: 2.5rem;
      }
    }
  }
}

.edit-metric-form {
  margin: -0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: $body-bg-light;
}

.metric-chart-tooltip {
  padding: 0.3rem 0.5rem;
  background-color: #fff;
  border: 1px solid $gray-500;
  border-radius: $border-radius;
  font-size: 0.75rem;

  .tooltip-title {
    font-weight: 400;
  }

  .tooltip-reading {
    font-weight: 600;
  }
}

.organization-card {
  cursor: pointer;
  background-color: #fff;
  padding: 1rem;
  min-height: 150px;
  border-radius: $border-radius;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  margin-bottom: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 28.5vh;
  max-height: 162.8px;

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }

  .mdi {
    margin-top: 1rem;
    font-size: 2.5rem;
    color: $brand-contrast;
  }

  .organization-name {
    font-size: 1.1rem;
    font-weight: 500;
  }

  .organization_selector_logo {
    width: auto;
    height: auto;
    max-width: 75%;
    max-height: 50%;
  }
}

.org_select__control {
  background-color: transparent !important;
}

@media print {
  #site-header {
    display: none;
  }

  #root {
    padding: 0;
  }

  #editor-container .print-heading {
    display: block;
  }

  #editor-container .print-heading-image {
    display: block;
  }

  #editor-container .print-heading-logo {
    display: block;
  }
  #editor-heading-container {
    .actions {
      display: none;
    }
  }
}

.trial-bar {
  background-color: #cbf1ff;
  color: black;
  font-weight: 600;
  font-size: 0.85rem;
  border-radius: 50rem;
  margin-right: 1.5rem;
  display: flex;
  align-items: stretch;

  div {
    padding: 0.2rem 0.5rem;
    display: flex;
    align-items: center;
  }

  a {
    display: block;
    margin-right: -0.5rem;
    background-color: $primary;
    border: 0;
    border-radius: 50rem;
    padding: 0.2rem 0.5rem;
    color: white;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

.payment-methods-list {
  .payment-method {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid $gray-400;

    &:last-child {
      border-bottom: none;
    }
  }

  .card-details {
    display: flex;
  }

  .card-number {
    font-weight: bold;
  }

  .card-expiry {
    margin-left: 2rem;
  }
}

.sync-kt-form {
  margin: -0.5rem;
  margin-top: 1rem;
  padding: 0.5rem;
  background-color: $body-bg-light;
}

.no-chart {
  text-align: center;
  height: 190px;
  color: $gray-500;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .no-data-icon {
    font-size: 4rem;
  }
}

.card-badge {
  position: absolute;
  top: 0;
  right: 0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.7rem;
  background-color: $primary;
  color: white;
  padding: 2px 7px;
  border-bottom-left-radius: $border-radius-sm;
  border-top-right-radius: $border-radius-sm;
  margin: -1px;
  overflow: hidden;
}

.folder-icon {
  font-size: 30px;
  color: $folder-color;
}

.folder-container {
  column-width: 350px;
  column-count: 4;
  column-rule: 1px dotted #ced4da;
  list-style: none;
  padding: 7px;
  background-color: #d9e7ec;

  .title {
    margin-left: 8px;
    color: #444444;
  }

  .badge-count {
    position: absolute;
    top: 3px;
    right: -3px;
  }

  .btn-actions {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #ddd;
  }

  .folder {
    position: relative;
    display: flex;
    align-items: center;
    break-inside: avoid;
    padding: 2px 5px;
    cursor: pointer;

    &:hover,
    &:focus-within {
      background-color: #b1d7e6;

      .title {
        color: #212529;
      }

      .folder-icon {
        color: darken($folder-color, 20%);
      }

      .btn-actions {
        display: block;
      }
    }
  }
}

.sub-heading {
  font-weight: 400;
}

.question-option {
  font-weight: bold;
  color: #000;
}

// ReactQuill content styles.
.ql-size-small {
  font-size: 0.75em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 10px !important;
}

// In Microsoft Edge, sticky positioning doesn't work when combined with dir "rtl" attribute
@supports (-ms-ime-align: auto) {
  [dir="rtl"] #editor-heading-container {
    h1 {
      position: relative;
    }
    .actions {
      position: absolute;
    }
  }
  [dir="rtl"] #editor {
    .lane-heading-name,
    .add-new-lane button {
      position: relative;
    }
    .persona-box,
    .stages {
      position: static;
    }
  }
}

.user-menu .dropdown-menu {
  left: -100px;
}
