// =====================================================================================================================
// Bootstrap variables
// =====================================================================================================================
$enable-gradients: true;

$primary: #3aa7d0;
$secondary: #7f8ea1;
$success: #22b66e;
$info: #337ab7;
$warning: #ffb400;
$danger: #e74c4b;
$dark: #36648b;

$theme-colors: (
  'primary': $primary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'dark': $dark,
  'brand-contrast': #f89b46,
);

$body-bg: #e8ebef;
$body-bg-light: #f3f5f7;

$font-size-base: 0.875rem; // 14px
$table-cell-padding: 0.5rem;

$table-bg: #f7f8f9;
$table-hover-bg: #f4f5f7;
$table-head-bg: $body-bg;

$dropdown-link-hover-color: darken(#212529, 5%);
$dropdown-link-hover-bg: lighten($primary, 40%);

$dropdown-item-padding-x: 0.75rem;

$modal-dialog-margin-y-sm-up: 5rem;
$modal-content-border-width: 0;
$modal-backdrop-opacity: 0.7;

$input-btn-focus-width: 0.1rem;

$nav-pills-border-radius: 50rem;

// =====================================================================================================================
// Mapovate variables
// =====================================================================================================================
$brand-contrast: #f89b46;
$main-menu-height: 61px;
$footer-height: 3rem;
$main-content-frame-padding: 1.5rem;
$editor-box-width: 280px;
$editor-space-between-boxes: 8px;

$step-rating-level1-fill-color: #a5261f;
$step-rating-level2-fill-color: #ff7500;
$step-rating-level3-fill-color: #ffce00;
$step-rating-level4-fill-color: #abba1a;
$step-rating-level5-fill-color: #39996b;

$journey-map-stage-background-color: #c8d4df;
$journey-map-box-background-color: #ecf0f4;
$journey-map-box-content-padding: 0.5rem;

$journey-map-persona-background-color: #f3f4f5;

$folder-color: #ebd297;

$sub-navigation-bg: #f6f7f9;
$sub-navigation-color: #8e9096;
$sub-navigation-active-color: $primary;
$sub-navigation-active-border-color: $primary;
